<template>
  <v-col>
    <v-btn
      height="auto"
      color="#c7c3c3"
      elevation="0"
      class="py-1"
      @click="openDocumentsDetails"
    >
      <v-icon>mdi-open-in-app</v-icon> Документы списком
    </v-btn>
    <component
      :is="activeComponent"
      :show.sync="showComponent"
    />
  </v-col>
</template>
<script>
export default {
  data(){
    return{
      activeComponent:null,
      showComponent:false,
    }
  },
  methods:{
    openDocumentsDetails(){
      this.activeComponent = () => import('@/components/dt/documents/documents-details/documents-details-modal.vue')
      this.showComponent = true
    },
  }
}
</script>
